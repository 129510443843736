import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledSection = styled.section`
  padding-bottom: 5rem;

  display: grid;
  justify-content: center;

  ul {
    list-style: none;

    li {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 0.6rem;
      align-items: baseline;
      margin-top: 1.2rem;

      ${({ theme }) => theme.mq.bigDesktop} {
        margin-top: 2rem;
      }
    }
  }
`;

const CenteredSection = ({ children }) => {
  return <StyledSection>{children}</StyledSection>;
};

CenteredSection.propTypes = {
  children: PropTypes.node.isRequired,
  isBig: PropTypes.bool,
};

export default CenteredSection;
